import React, { useEffect, useState } from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import * as API from '../../helpers/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import ImageIcon from '@material-ui/icons/Image';

function AdPlaylistList() {
  let [apiData, setApiData] = useState([]);
  let [columns, setColumns] = useState([]);
  let [isBusy, setBusy] = useState(true);
  let [initialPage, setinitialPage] = useState(1);

  useEffect(() => {
    (async () => {
      const playlistsRaw = await API.get(config.api.adPlaylist);
      setApiData(playlistsRaw);
      const imagesRaw = await API.get(config.api.uploads, {
        type: { $regex: '.*image.*' },
      });
      setinitialPage(localStorage.getItem('initialPage'));
      const images = imagesRaw;
      setColumns([
        { title: 'Name', field: 'name' },
        {
          title: 'Image',
          render: (rowData) => {
            let image = '';
            images.map((img) => {
              if (rowData._id === img.obj_id)
                image = <ImageIcon color='primary' />;
            });
            return image;
          },
        },
      ]);
      setBusy(false);
    })();
  }, [isBusy]);

  if (!isBusy) {
    return (
      <ListPage
        apiData={apiData}
        columns={columns}
        initialPage={initialPage ? initialPage : 0}
        CLICK={true}
        pageSize={config.pageSize}
        title={'Ad PLaylists'}
      />
    );
  } else {
    return <LinearProgress color='primary' />;
  }
}

export default AdPlaylistList;
