import React, { useEffect, useState, forwardRef } from 'react';
import MaterialTable from 'material-table';
import Add from '@material-ui/icons/AddBox';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

import { useParams } from 'react-router-dom';
import * as API from '../../helpers/api';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

function SortableTable(props) {
  const { useState } = React;
  const [data, setdata] = useState([]);
  const [editable, setEditable] = useState([]);
  const [actions, setActions] = useState([]);
  const [tracks, settracks] = useState([]);
  const [isBusy, setBusy] = useState(true);
  let id = useParams().id;

  const tableIcons = {
    Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownwardIcon {...props} ref={ref} />
    )),
  };

  useEffect(() => {
    (async () => {
      if (props.noControl === undefined || props.noControl === false) {
        setActions([
          {
            tooltip: 'Remove All Selected items',
            icon: tableIcons.Delete,
            onClick: (evt, rows) => {
              Promise.all(
                rows.map(
                  (row) =>
                    new Promise((resolve, reject) => {
                      row.playlist_id = undefined;
                      API.update(props.route, row._id, row)
                        .then((res) => {
                          resolve(res);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    })
                )
              ).then((status) => {
                setBusy(true);
              });
            },
          },
        ]);
        setEditable({
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              const dataUpdate = [...tracks];
              const index = oldData.tableData.id;
              dataUpdate[index] = newData;
              API.update(props.route, dataUpdate[index]._id, newData).then(
                () => {
                  settracks([...dataUpdate]);
                  resolve();
                  setBusy();
                }
              );
            }),
        });
      }
      if (props.data) {
        setdata(props.data);
        setBusy(false);
      } else {
        const rawData = await API.get(
          props.route,
          { playlist_id: id },
          { queue: 1 }
        );

        setdata(rawData);
        setBusy(false);
      }
      setBusy(false);
    })();
  }, [isBusy]);

  if (!isBusy) {
    return (
      <div style={{ maxWidth: '100%' }}>
        {data && data.length > 0 ? (
          <MaterialTable
            title={props.title}
            columns={props.columns}
            data={data}
            icons={tableIcons}
            onChangePage={(page) => {
              localStorage.setItem('initialPage', page);
            }}
            options={{
              pageSize: props.pageSize,
              initialPage: +props.initialPage,
              selection: props.selection === undefined ? true : false,
            }}
            actions={actions}
            editable={editable}
          />
        ) : null}
      </div>
    );
  } else {
    return <LinearProgress color='primary' />;
  }
}

export default SortableTable;
