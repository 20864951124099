import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';
import Container from '@material-ui/core/Container';
import { MuiThemeProvider } from 'material-ui/styles';
import SortableTable from '../modules/playlistPageTracks';

import getMuiTheme from 'material-ui/styles/getMuiTheme';
import { blue500, blue700 } from 'material-ui/styles/colors';
const theme = getMuiTheme({
  palette: {
    primary1Color: blue500,
    primary2Color: blue700,
  },
});

export default function ClientPlaylistPage(props) {
  let history = useHistory();
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const [apiData, setApiData] = useState('');

  //! component specific properties
  const [name, setName] = useState('');
  const [image, setImage] = useState(undefined);

  let modelSendToServer = {
    name: name,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.clientPlaylist, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.clientPlaylist, id, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function removeItem() {
    await API.remove(config.api.clientPlaylist, id);
    history.goBack();
    setBusy(true);
  }
  async function removeFile(id) {
    await API.remove(config.api.uploads, id);
    setImage(undefined);
  }
  async function uploadFile(files, lang) {
    setBusy(true);
    if (id === 'add') {
      const rowData = await API.post(
        config.api.clientPlaylist,
        modelSendToServer
      );
      id = rowData._id;
      await history.push(`${id}`);
      await API.upload(id, files, lang);
    } else {
      await API.upload(id, files, lang);
    }
    setBusy(false);
  }

  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const rowData = await API.get(config.api.clientPlaylist, { _id: id });

        if (rowData[0]) {
          setApiData(rowData[0]);
          setName(rowData[0].name);
        }

        const rowImages = await API.get(config.api.uploads, {
          obj_id: id,
          type: { $regex: '.*image.*' },
        });
        setImage(rowImages[0]);
      }

      await setBusy(false);
    })();
  }, [isBusy, history.location.pathname]);

  if (!isBusy) {
    return (
      <MuiThemeProvider muiTheme={theme}>
        <SinglePage
          inputs={[
            {
              value: name,
              setter: setName,
              type: 'textfield',
              label: 'Name',
            },
          ]}
          // filesComponent={[
          //     {
          //         name: "Image",
          //         file: image,
          //         filesUploadLimit: 1,
          //         acceptedFiles: ['image/*'],
          //         uploadFile: uploadFile,
          //         removeFile: removeFile,
          //         lang: 'en',
          //         buttonAddText: "add",
          //         buttonOpenText: "open",
          //         buttonRemoveText: "remove",
          //         dropzoneText:
          //             "Click on the 'Add' button to add image"
          //     }
          // ]}
          sortableTable={{
            title: '',
            route: config.api.client,
            playlistRoute: config.api.clientPlaylist,
            columns: [{ title: 'Name', field: 'name' }],
          }}
          isBusy={isBusy}
          setBusy={setBusy}
          apiData={apiData}
          route={config.api.clientPlaylist}
          CREATE={createItem}
          UPDATE={updateItem}
          REMOVE={removeItem}
        />
      </MuiThemeProvider>
    );
  } else {
    return <LinearProgress color='primary' />;
  }
}
