import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ListPage } from '../ListPage';

export default function StreamPage() {
  let history = useHistory();
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  //! component specific properties

  // Simple types
  const [name, setName] = useState('');
  const [History, setHistory] = useState([]);
  const [onSecond, setSecond] = useState(0);
  const [adInterval, setAdInterval] = useState(0);
  const [shuffle, setShuffle] = useState(Boolean);

  const [loading, setloading] = useState(true);
  const [playing, setplaying] = useState(false);
  const [stop, setstop] = useState(false);

  // Objectz_ids types
  const [adPlaylistId, setAdPlaylistId] = useState('');
  const [adPlaylist, setAdPlaylist] = useState('');
  const [adPlaylists, setAdPlaylists] = useState('');
  const [trackPlaylistId, setTrackPlaylistId] = useState('');
  const [trackPlaylist, setTrackPlaylist] = useState('');
  const [trackPlaylists, setTrackPlaylists] = useState('');
  const [clientPlaylistId, setClientPlaylistId] = useState('');
  const [clientPlaylist, setClientPlaylist] = useState('');
  const [clientPlaylists, setClientPlaylists] = useState('');
  const [playingCompositionId, setPlayingCompositionId] = useState('');
  const [image, setImage] = useState(undefined);

  let [streaming, setStreaming] = useState(false);

  let modelSendToServer = {
    name,
    onSecond,
    adInterval,
    streaming,
    shuffle,
    clientPlaylistId,
    playingCompositionId,
    ad_playlist_id: adPlaylist ? adPlaylist._id : null,
    track_playlist_id: trackPlaylist ? trackPlaylist._id : null,
    client_playlist_id: clientPlaylist ? clientPlaylist._id : null,
  };

  const handleStreamState = (e) => {
    if (e.currentTarget.value == false) {
      setStreaming(true);
    } else if (e.currentTarget.value == true) {
      setStreaming(false);
    } else {
      setStreaming(!streaming);
    }
    setloading(true);
    console.log('🚀 - handleStreamState - streaming:', !streaming);
    updateItem(!streaming);
  };

  const handleShuffle = () => {
    if (shuffle === null || !shuffle) setShuffle(true);
    else if (shuffle) setShuffle(false);
  };

  //! components specific functions
  async function createItem() {
    await API.post(config.api.stream, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
    setBusy(true);
  }
  async function updateItem(streaming) {
    if (streaming !== undefined) modelSendToServer.streaming = streaming;
    await API.update(config.api.stream, id, modelSendToServer);
    // let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    // history.push(previousUrl);
    // setBusy(true);
  }
  async function removeItem() {
    await API.remove(config.api.stream, id);
    history.goBack();
    setBusy(true);
  }
  async function removeFile(id) {
    setImage('');
    await API.remove(config.api.uploads, id);
    setBusy(true);
  }
  async function uploadFile(
    files,
    lang,
    destiny,
    thumbSizeWidth,
    thumbSizeHeight,
    responsiveImages
  ) {
    if (id === 'add') {
      try {
        modelSendToServer.name = files[0].name.split('.')[0];
        const rowData = await API.post(config.api.ad, modelSendToServer);
        id = rowData._id;
        await API.upload(
          id,
          files,
          lang,
          destiny,
          thumbSizeWidth,
          thumbSizeHeight,
          responsiveImages
        );
      } catch (error) {
        console.error(error);
      }
      await history.push(`${id}`);
    } else {
      await API.upload(
        id,
        files,
        lang,
        destiny,
        thumbSizeWidth,
        thumbSizeHeight,
        responsiveImages
      );
    }
    setBusy(true);
  }
  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const rowData = await API.get(config.api.stream, { _id: id });
        const rowImage = await API.get(config.api.uploads, {
          obj_id: id,
          type: { $regex: '.*image.*' },
        });
        const rowAdPlaylists = await API.get(config.api.adPlaylist);
        const rowTrackPlaylists = await API.get(config.api.trackPlaylist);
        const rowClientPlaylists = await API.get(config.api.clientPlaylist);

        Promise.all([
          rowData,
          rowImage,
          rowAdPlaylists,
          rowTrackPlaylists,
          rowClientPlaylists,
        ]).then((value) => {
          if (value[0][0]) {
            let rowData = value[0][0];
            setHistory(rowData.history);
            setName(rowData.name);
            setSecond(rowData.onSecond);
            setAdInterval(rowData.adInterval);
            setShuffle(rowData.shuffle);
            setPlayingCompositionId(rowData.playingCompositionId);
            if (rowData.streaming) setStreaming(true);

            if (value[1][0]) setImage(value[1][0]);

            if (value[2][0]) {
              let rowPlaylists = value[2];
              setAdPlaylists(rowPlaylists);
              rowPlaylists.forEach((pllist) => {
                if (pllist._id === value[0][0].ad_playlist_id) {
                  setAdPlaylist(pllist);
                }
              });
            }
            if (value[3][0]) {
              let rowPlaylists = value[3];
              setTrackPlaylists(rowPlaylists);
              rowPlaylists.forEach((pllist) => {
                if (pllist._id === value[0][0].track_playlist_id) {
                  setTrackPlaylist(pllist);
                }
              });
            }
            if (value[4][0]) {
              let rowPlaylists = value[4];
              setClientPlaylists(rowPlaylists);
              rowPlaylists.forEach((pllist) => {
                if (pllist._id === value[0][0].client_playlist_id) {
                  setClientPlaylist(pllist);
                }
              });
            }
          } else {
            console.error(
              'We cannot get page information from server, please connect to site administrator'
            );
          }
          setBusy(false);
        });
      } else {
        // getting APi playlists
        //! AD
        API.get(config.api.adPlaylist)
          .then((res) => {
            setAdPlaylists(res.data);
          })
          .catch((err) => console.error(err));

        //! Track
        API.get(config.api.trackPlaylist)
          .then((res) => {
            setTrackPlaylists(res.data);
          })
          .catch((err) => console.error(err));

        //! Client
        API.get(config.api.clientPlaylist)
          .then((res) => {
            setClientPlaylists(res.data);
          })
          .catch((err) => console.error(err));

        setBusy(false);
      }
    })();
  }, [isBusy]);

  if (isBusy === null) return null;
  else if (isBusy === true) return <LinearProgress color='primary' />;
  else if (isBusy === false)
    return (
      <>
        <SinglePage
          inputs={[
            {
              value: name,
              setter: setName,
              type: 'textfield',
              label: 'Name',
            },
            {
              value: adInterval,
              setter: setAdInterval,
              type: 'number',
              label: 'Ad count',
              inputProps: { min: '2', step: '1' },
            },
            {
              value: shuffle,
              setter: handleShuffle,
              type: 'checkbox',
              label: 'Shuffle',
            },
            {
              value: adPlaylist,
              setter: setAdPlaylist,
              type: 'optionList',
              optionListValue: adPlaylists,
              label: 'Ad Playlists',
            },
            {
              value: trackPlaylist,
              setter: setTrackPlaylist,
              type: 'optionList',
              optionListValue: trackPlaylists,
              label: 'Track Playlists',
            },
            {
              value: clientPlaylist,
              setter: setClientPlaylist,
              type: 'optionList',
              optionListValue: clientPlaylists,
              label: 'Client Playlists',
            },
          ]}
          filesComponent={[
            {
              name: 'image',
              file: image,
              filesUploadLimit: 1,
              acceptedFiles: ['image/*'],
              uploadFile: uploadFile,
              removeFile: removeFile,
              destiny: 'image',
              buttonAddText: 'add',
              buttonOpenText: 'open',
              buttonRemoveText: 'remove',
              dropzoneText: 'Click on the "Add" button to add image',
              thumbSizeWidth: 270,
              responsiveImages: true,
            },
          ]}
          isBusy={isBusy}
          setBusy={setBusy}
          route={config.api.stream}
          handleStreamState={handleStreamState}
          streaming={streaming}
          stop={stop}
          loading={loading}
          CREATE={createItem}
          UPDATE={updateItem}
          REMOVE={removeItem}
          sortableTable={{
            title: 'History',
            selection: false,
            noControl: true,
            data: History,
            columns: [
              {
                title: 'Date',
                field: 'changeDateTime',
                render: (rowData) =>
                  new Date(rowData.changeDateTime).toLocaleString(),
              },
              { title: 'Track', field: 'track' },
            ],
          }}
        />
      </>
    );
}
