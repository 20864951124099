import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function ClientPage() {
  let history = useHistory();
  let id = useParams().id;
  const [isBusy, setBusy] = useState(null);
  const [image, setImage] = useState(undefined);
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [playlist_id, setPlaylist_id] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [playlists, setPlaylists] = useState('');

  let modelSendToServer = {
    name,
    username,
    password,
    playlist_id: playlist ? playlist._id : null,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.client, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.client, id, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function removeItem() {
    await API.remove(config.api.client, id);
    history.goBack();
    setBusy(true);
  }
  async function removeFile(id) {
    await API.remove(config.api.uploads, id);
    setImage(undefined);
  }
  async function uploadFile(files, lang) {
    if (id === 'add') {
      try {
        const rowData = await API.post(config.api.client, modelSendToServer);
        id = rowData._id;
        await API.upload(id, files, lang);
      } catch (error) {
        console.error(error);
      }
      await history.push(`${id}`);
      setBusy(true);
    } else {
      await API.upload(id, files, lang);
      setBusy(true);
    }
  }

  //promise.all
  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const rowData = API.get(config.api.client, { _id: id });
        const rowImage = API.get(config.api.uploads, {
          obj_id: id,
          type: { $regex: '.*image.*' },
        });
        const rowPlaylists = API.get(config.api.clientPlaylist);
        Promise.all([rowData, rowImage, rowPlaylists]).then((value) => {
          if (value[0][0]) {
            let rowData = value[0][0];
            setName(rowData.name);
            setUsername(rowData.username);
            setPassword(rowData.password);
            setPlaylist_id(rowData.playlist_id);

            if (value[1][0]) setImage(value[1][0]);

            if (value[2][0]) {
              let rowPlaylists = value[2];
              setPlaylists(rowPlaylists);
              rowPlaylists.forEach((pllist) => {
                if (pllist._id === value[0][0].playlist_id) {
                  setPlaylist(pllist);
                }
              });
            }
          } else {
            console.error(
              'We cannot get page information from server, please connect to site administrator'
            );
          }
        });
      } else {
        API.get(config.api.clientPlaylist)
          .then((res) => {
            setPlaylists(res);
          })
          .catch((err) => console.error(err));
      }
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy === null) return null;
  else if (isBusy === true) return <LinearProgress color='primary' />;
  else if (isBusy === false)
    return (
      // return (
      <SinglePage
        inputs={[
          {
            value: name,
            setter: setName,
            type: 'textfield',
            label: 'Name',
          },
          {
            value: username,
            setter: setUsername,
            type: 'textfield',
            label: 'Username',
          },
          {
            value: password,
            setter: setPassword,
            type: 'password',
            label: 'Password',
          },
          {
            value: playlist,
            setter: setPlaylist,
            type: 'optionList',
            optionListValue: playlists,
            label: 'Playlists',
          },
        ]}
        filesComponent={[
          {
            name: 'image',
            file: image,
            filesUploadLimit: 1,
            acceptedFiles: ['image/*'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'add',
            buttonOpenText: 'open',
            buttonRemoveText: 'remove',
            dropzoneText: 'Click on the "Add" button to add image',
          },
        ]}
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.client}
        CREATE={createItem}
        UPDATE={updateItem}
        REMOVE={removeItem}
      />
    );
}
