import React from "react";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import QueueMusicIcon from "@material-ui/icons/QueueMusic";
import AudiotrackIcon from "@material-ui/icons/Audiotrack";
import AlbumIcon from "@material-ui/icons/Album";
import PeopleIcon from "@material-ui/icons/People";
import Person from "@material-ui/icons/Person";
import { red, green, blue, orange } from "@material-ui/core/colors";
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
export default [
  {
    name: "track playlists",
    route: "track-playlist",
    icon: <AlbumIcon style={{ color: blue[500] }} />,
  },
  { name: "tracks", route: "track", icon: <AudiotrackIcon style={{ color: blue[500] }} /> },

  {
    name: "ad playlists",
    route: "ad-playlist",
    icon: <AlbumIcon style={{ color: orange[500] }} />,
  },
  {
    name: "ads",
    route: "ad",
    icon: <AudiotrackIcon style={{ color: orange[500] }} />,
  },

  {
    name: "client playlists",
    route: "client-playlist",
    icon: <PeopleIcon />,
  },
  {
    name: "client",
    route: "client",
    icon: <Person />,
  },
  {
    name: "stream",
    route: "stream",
    icon: <SlowMotionVideoIcon style={{ color: red[500] }} />,
  },
];
