import React, { useState, useEffect } from 'react';

// Helpers
import history from './helpers/history';
import { cookies, setCookies } from './helpers/cookies';
import { useStateWithLocalStorage } from './helpers/storage';
import { isLoggedIn } from './helpers/auth';
import API from './helpers/api';
import LinearProgress from '@material-ui/core/LinearProgress';

import config from './config';

// Components
import StreamList from './components/stream/streamList';
import StreamPage from './components/stream/streamPage';
import TrackPlaylistList from './components/track-playlist/trackPlaylistList';
import TrackPlaylistPage from './components/track-playlist/trackPlaylistPage';
import TrackList from './components/track/trackList';
import TrackPage from './components/track/trackPage';
import AdPlaylistList from './components/ad-playlist/adPlaylistList';
import AdPlaylistPage from './components/ad-playlist/adPlaylistPage';
import AdList from './components/ad/adList';
import AdPage from './components/ad/adPage';
import ClientPlaylistList from './components/client-playlist/clientPlaylistList';
import ClientPlaylistPage from './components/client-playlist/clientPlaylistPage';
import ClientList from './components/client/clientList';
import ClientPage from './components/client/clientPage';
// ----------------------------------------------------
import MediaList from './components/media/mediaList';
// global components
import Login from './components/login';
import Home from './components/home';
import Menu from './components/menu/menu';

//Routing
import { Router, Route, Switch } from 'react-router-dom';
import './App.css';

function MainComponent() {
  return (
    <Route path='/' component={Home}>
      <Route path='/home' component={Home} />
      <Route exact path='/stream' component={StreamList} />
      <Route exact path='/stream/:id' component={StreamPage} />
      <Route exact path='/track-playlist' component={TrackPlaylistList} />
      <Route exact path='/track-playlist/:id' component={TrackPlaylistPage} />
      <Route exact path='/track' component={TrackList} />
      <Route exact path='/track/:id' component={TrackPage} />
      <Route exact path='/ad-playlist' component={AdPlaylistList} />
      <Route exact path='/ad-playlist/:id' component={AdPlaylistPage} />
      <Route exact path='/ad' component={AdList} />
      <Route exact path='/ad/:id' component={AdPage} />
      <Route exact path='/client-playlist' component={ClientPlaylistList} />
      <Route exact path='/client-playlist/:id' component={ClientPlaylistPage} />
      <Route exact path='/client' component={ClientList} />
      <Route exact path='/client/:id' component={ClientPage} />
      <Route exact path='/media' component={MediaList} />
    </Route>
  );
}

function App() {
  let [loggedIn, setLoggedIn] = useState(null);
  let [username, setUsername] = useStateWithLocalStorage('username');

  async function logout() {
    await setCookies('');
    await setUsername('');
    await setLoggedIn(false);
  }

  function login(username, password) {
    if (username && password) {
      API.post(config.authentication.login, {
        username,
        password,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(res.data.token);
            setLoggedIn(true);
            setUsername(res.data.user.name);
          }
        })
        .catch((error) => {
          console.error(error);
          setLoggedIn(false);
        });
    } else {
      setLoggedIn(false);
      console.log('There is no user with provided username & password');
    }
  }

  function checkToken() {
    let token = cookies.get(config.authentication.tokenAddress);
    if (token) {
      API.post(config.authentication.verify, {
        token,
      })
        .then((res) => {
          if (res.status === 200) {
            setCookies(token);
            setUsername(res.data.user.name);
            setLoggedIn(true);
          } else {
            setLoggedIn(false);
          }
        })
        .catch((res) => {
          console.error(`Please don't edit cookies manually`);
          cookies.remove('authorization');
        });
    } else {
      setLoggedIn(false);
      console.log(
        'You must provide token for calling login -> veryfyToken functions, Or mongodb database is not running'
      );
    }
  }

  useEffect(() => {
    (async () => {
      await checkToken();
    })();
  }, []);

  if (loggedIn === null) return <LinearProgress color='primary' />;
  else if (loggedIn === false)
    return (
      <isLoggedIn.Provider value={{ login, logout, loggedIn }}>
        {<Login />}
      </isLoggedIn.Provider>
    );
  else
    return (
      <isLoggedIn.Provider value={{ login, logout }}>
        <Router history={history}>
          <Switch>
            <Menu component={MainComponent} />
          </Switch>
        </Router>
      </isLoggedIn.Provider>
    );
}

export default App;
