import React, { useState, Fragment } from 'react';
import * as API from '../../helpers/api';
import config from '../../config';
import { DropzoneDialog, DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
// ! upload stuff
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
// ! Delete button with icon
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import LaunchIcon from '@material-ui/icons/Launch';
import AddIcon from '@material-ui/icons/Add';

// ! grid stuff
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// ! Card stuff
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

//! button group
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Typography } from '@material-ui/core';

//! React audio player
import ReactAudioPlayer from 'react-audio-player';

const useStyles = makeStyles((theme) => ({
  image: {
    margin: theme.spacing(1),
    // clear: 'both',
    width: '45%',
    float: 'left',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      float: 'none',
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 300,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    backgroundSize: 'contain',
  },
  dropzone: {
    overflow: 'hidden',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    height: 'auto',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  ['gridList li']: {
    height: 'auto',
  },
  gridTitle: {
    color: theme.palette.secondary.light,
  },
  gridTitleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

export function FilesComponent(props) {
  const [openDialog, setDialog] = useState(false);
  const classes = useStyles();

  let disabled = false;
  let fileId = '';
  let fileLang = '';
  let URL = '';
  let fileTypePdf = false;
  let fileTypeAudio = false;
  let fileTypeImage = false;
  let maxFileSize = 0;

  if (props.file === undefined) {
    disabled = true;
  } else if (props.file) {
    fileId = props.file._id;
    fileLang = props.lang;
  }

  if (props.acceptedFiles[0].includes('pdf')) {
    if (props.file) URL = `${config.api.API_URL}/${props.file.path}`;
    fileTypePdf = true;
    maxFileSize = 2000000;
  } else if (props.acceptedFiles[0].includes('audio')) {
    if (props.file)
      URL = `${config.api.API_URL}/${config.api.API_Version}/play/${props.file._id}`;
    fileTypeAudio = true;
    maxFileSize = 25000000;
  } else if (props.acceptedFiles[0].includes('image')) {
    if (props.file) URL = `${config.api.API_URL}/${props.file.path}`;
    fileTypeImage = true;
    maxFileSize = 5000000;
  }

  const handleClose = () => {
    setDialog(false);
  };

  const handleSave = (files) => {
    setDialog(false);
    props.uploadFile(
      files,
      props.lang,
      props.destiny,
      props.thumbSizeWidth,
      props.thumbSizeHeight,
      props.responsiveImages
    );
  };

  const handleOpen = () => {
    setDialog(true);
  };

  const openInNewTab = (e) => {
    let win = window.open(URL, '_blank');
    win.focus();
  };

  const removeFile = (e) => {
    props.removeFile(e.currentTarget.id);
  };

  let [screenWidth] = useState(window.innerWidth.toString());

  return (
    <Fragment>
      <Card className={`${classes.image} inputFileComponent`}>
        <CardContent>
          {!disabled && URL ? (
            fileTypePdf ? (
              <embed
                className={classes.media}
                type='application/pdf'
                width='100%'
                src={URL}
              />
            ) : fileTypeImage ? (
              <CardMedia className={classes.media} image={URL} />
            ) : fileTypeAudio ? (
              <ReactAudioPlayer src={URL} autoPlay controls />
            ) : (
              <LinearProgress />
            )
          ) : (
            <Typography variant='h5' className={classes.media}>
              {props.dropzoneText}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <DropzoneDialog
            open={openDialog}
            onSave={handleSave}
            acceptedFiles={props.acceptedFiles}
            showPreviews={true}
            maxFileSize={maxFileSize}
            onClose={handleClose}
            filesLimit={props.filesUploadLimit}
            dropzoneText={props.dropzoneText}
          />
          <div className={classes.buttonGroup}>
            {!disabled && URL ? (
              <ButtonGroup
                variant='contained'
                color='primary'
                aria-label='contained primary button group'
              >
                <Button
                  variant='contained'
                  color='default'
                  endIcon={<LaunchIcon />}
                  disabled={props.slider || disabled}
                  display={props.slider ? 'none' : 'inline'}
                  onClick={openInNewTab}
                >
                  {screenWidth < 500 ? null : props.buttonOpenText}
                </Button>

                <Button
                  variant='contained'
                  color='secondary'
                  startIcon={<DeleteIcon />}
                  id={fileId}
                  onClick={removeFile}
                >
                  {screenWidth < 500 ? null : props.buttonRemoveText}
                </Button>
              </ButtonGroup>
            ) : (
              <Button
                variant='contained'
                color='primary'
                startIcon={<AddIcon />}
                id={fileId}
                lang={fileLang}
                onClick={handleOpen}
              >
                {screenWidth < 500 ? null : props.buttonAddText}
              </Button>
            )}
          </div>
        </CardActions>
      </Card>
    </Fragment>
  );
}
