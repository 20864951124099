import React, { useState, Fragment, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Editor } from "@tinymce/tinymce-react";
import Typography from "@material-ui/core/Typography";

// !Select option stuff
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

// !Date stuff
import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// show-hide password stuff
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import IconButton from "@material-ui/core/IconButton";
import useMediaQuery from '@material-ui/core/useMediaQuery';
const useStyles = makeStyles((theme) => ({
  root: {
    "&": {
      margin: theme.spacing(2),
      float: "left",
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },
  number: {
    width: '80px'
  }
}));

const Input = (props) => {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState();

  const handleChange = (e) => {
    props.setter(e.target && e.target.value);
  };

  const handleTextFieldChange = (data) => {
    props.setter(data);
  };

  const handleNumberChange = (e) => {
    if (props.inputProps.min) {
      if ((e.target && e.target.value) < props.inputProps.min) {
        props.setter(props.inputProps.min);
      } else {
        props.setter(e.target && e.target.value);
      }
    }
  };

  const handleDateCHange = (date) => {
    props.setter(date);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  if (props.type === "textfield") {
    return (
      <div className={classes.root}>
        <TextField
          id={Date.now().toString()}
          label={props.label}
          variant="outlined"
          type="text"
          value={props.value}
          inputProps={props.inputProps}
          onChange={handleChange}
        />
      </div>
    );
  } else if (props.type === "number") {
    return (
      <div className={`${classes.root} ${classes.number}`}>
        <TextField
          id={Date.now().toString()}
          label={props.label}
          variant="outlined"
          type="number"
          value={props.value}
          onChange={handleNumberChange}
        />
      </div>
    );
  } else if (props.type === "password") {
    return (
      <div className={classes.root}>
        <FormControl variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? "text" : "password"}
            value={props.value}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </div>
    );
  } else if (props.type === "textarea") {
    return (
      <div className={classes.root}>
        <Typography variant="h4" component="h2">
          {props.label}
        </Typography>
        <Editor
          initialValue={props.value}
          init={{
            height: 500,
            menubar: "file edit insert view format table",
            plugins: [
              "charmap advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste code help wordcount",
            ],
            formatpainter_blacklisted_formats:
              "link,address,removeformat,formatpainter_removeformat",
            toolbar:
              "charmap | undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help",
          }}
          value={props.value}
          onEditorChange={handleTextFieldChange}
        />
      </div>
    );
  } else if (props.type === "optionList") {
    return (
      <div className={classes.root}>
        <FormControl variant="outlined">
          <InputLabel id="Category">{props.label}</InputLabel>
          <Select
            value={props.value}
            label={props.label}
            onChange={handleChange}
          >
                <MenuItem>
                  none
                </MenuItem>
            {props.optionListValue &&
              props.optionListValue.map((option, index) => (
                <MenuItem value={option} key={index}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    );
  } else if (props.type === "datePicker") {
    return (
      <div className={classes.root}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justify="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              format="MM/dd/yyyy"
              value={props.value}
              onChange={handleDateCHange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </div>
    );
  } else if (props.type === "checkbox") {
    return (
      <div className={classes.root}>

        <FormControlLabel
          control={
            <Checkbox
              checked={props.value}
              onChange={props.setter}
              name="Shuffle"
              color="primary"
            />
          }
          label={props.label}
        />
      </div>
    );
  } else {
    return <h1>There is no content to show in input</h1>;
  }
};

export default Input;
