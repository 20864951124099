import React, { useState, Fragment } from 'react';
import * as API from '../../helpers/api';
import config from '../../config';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
// ! upload stuff
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';

// ! grid stuff
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';

// ! Card stuff
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActionArea from '@material-ui/core/CardActionArea';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  image: {
    maxWidth: 345,
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  margin: {
    margin: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  media: {
    height: 260,
  },
  dropzone: {
    overflow: 'hidden',
  },
  grid: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    flexWrap: 'nowrap',
    height: 'auto',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
  ['gridList li']: {
    height: 'auto',
  },
  gridTitle: {
    color: theme.palette.secondary.light,
  },
  gridTitleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
}));

export function ImageComponent(props) {
  const classes = useStyles();

  return (
    <Fragment>
      <Card className={classes.image}>
        {props.imageObj.path ? (
          <Fragment>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={`${config.api.imgUrl}${props.imageObj.path}`}
              />
            </CardActionArea>
            <CardActions>
              <Button
                className={classes.button}
                variant='contained'
                color='secondary'
                startIcon={<DeleteIcon />}
                id={props.imageObj._id}
                onClick={props.removeImage}>
                {props.buttonText}
              </Button>
            </CardActions>
          </Fragment>
        ) : (
          <CardActionArea>
            <DropzoneArea
              acceptedFiles={['image/*']}
              dropzoneText={props.dropzoneText}
              onChange={props.uploadImage}
              filesLimit={props.filesLimit}
            />
          </CardActionArea>
        )}
      </Card>
    </Fragment>
  );
}
