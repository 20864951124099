import React, { useState, useEffect, Fragment } from 'react';
import SinglePage from '../SinglePage';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';

// For clipboard stuff
import Paper from '@material-ui/core/Paper';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Divider } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'hidden',
    justifyContent: 'space-between',
    // '& > *': {
    //     margin: theme.spacing(1),
    //     width: theme.spacing(16),
    //     height: theme.spacing(16),
    // },
  },
  image: {
    maxWidth: 345,
    margin: theme.spacing(1),
  },
  media: {
    height: 260,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

export default function MediaList() {
  let classes = useStyles();
  let history = useHistory();
  let id = useParams().id;
  const [isBusy, setBusy] = useState(true);
  const [files, setFiles] = useState('');

  const copyToPlipboard = (e) => {
    let url = e.currentTarget.id;
    navigator.clipboard.writeText(url);
  };

  //! components specific functions
  async function removeFile(e) {
    setBusy(true);
    await API.remove(config.api.uploads, e.currentTarget.id);
  }
  async function uploadFile(files, lang) {
    setBusy(true);
    await API.upload(id, files, lang);
    setBusy(false);
  }

  useEffect(() => {
    (async () => {
      const rowData = await API.get(config.api.uploads, {
        type: { $regex: '.*image.*' },
      });
      setFiles(rowData.data);
      await setBusy(false);
    })();
  }, [isBusy, history.location.pathname]);

  return (
    <Fragment>
      {isBusy ? (
        <LinearProgress color='primary' />
      ) : (
        <Fragment>
          <div className={classes.root}>
            <DropzoneArea
              filesLimit={100}
              dropzoneText={'Перетащите до 10 изображения'}
              dropzoneClass={classes.media}
              showPreviews={false}
              // showPreviewsInDropzone={false}
              // useChipsForPreview={false}
              onChange={uploadFile}
              // onDelete={handleFeatureImgDelete}
            />
            <Divider />
            {files.map((mediaFile) => (
              <Card className={classes.image} key={mediaFile._id}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={`http://localhost:27555/${mediaFile.path}`}
                  />
                </CardActionArea>
                <CardActions>
                  <ButtonGroup>
                    <Button
                      variant='contained'
                      color='primary'
                      className={classes.button}
                      startIcon={<FileCopyIcon />}
                      id={`http://localhost:27555/${mediaFile.path}`}
                      onClick={copyToPlipboard}>
                      Ссылка
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={classes.button}
                      startIcon={<DeleteIcon />}
                      id={mediaFile._id}
                      onClick={removeFile}>
                      Удалить
                    </Button>
                  </ButtonGroup>
                </CardActions>
              </Card>
            ))}
          </div>
        </Fragment>
      )}
    </Fragment>
  );
}
