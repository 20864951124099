const config = {
  api: {
    stream: 'stream',
    trackPlaylist: 'track-playlist',
    track: 'track',
    adPlaylist: 'ad-playlist',
    ad: 'ad',
    clientPlaylist: 'client-playlist',
    client: 'client',
    connectedClients: 'connected-clients',
    uploads: 'uploads',

    API_URL: process.env.REACT_APP_API_URL,
    API_Version: 'v1',
    CHAT_URL: process.env.REACT_APP_CHAT_URL,
    MAINAPP_URL: process.env.REACT_APP_MAINAPP_URL,
  },
  authentication: {
    login: '/login/private',
    verify: '/verify/private',
    tokenAddress: 'private-token',
  },
  pageSize: 20,
  translate: {
    dropzoneText: {
      en: 'There is no file, click to upload a new one',
      ru: 'Файла нет, нажмите, чтобы загрузить новыйe',
      hy: 'Ֆայլ չկա, սեղմեք՝ նորը վերբեռնելու համար',
    },
    linkCopied: {
      en: 'Link copied',
      ru: 'Ссылка скопирована',
      hy: 'Լինկը պատճենվել է',
    },
    changedDate: {
      en: 'Change Date',
      ru: 'Дата изменения',
      hy: 'Փոփոխության ամսաթիվը',
    },
    user: {
      en: 'User',
      ru: 'Пользователь',
      hy: 'Օգտատեր',
    },
    historyTable: {
      en: 'History table',
      ru: 'Таблица истории',
      hy: 'Պատմության աղյուսակ',
    },
    banner: {
      en: 'Banner',
      ru: 'Баннер',
      hy: 'Բաններ',
    },
    ordersCart: {
      en: 'Orderes chart',
      ru: 'График заказов',
      hy: 'Պատվերների գծապատկեր',
    },
    quantity: {
      en: 'Quantity',
      ru: 'Количество',
      hy: 'Քանակ',
    },
    color: {
      en: 'Color',
      ru: 'Цвет',
      hy: 'Գույն',
    },
    addToList: {
      en: 'Add to list',
      ru: 'Добавить в лист',
      hy: 'Ավելացնել լիստում',
    },
    noContent: {
      en: 'There is no content',
      ru: 'Нет контента',
      hy: 'Բովանդակություն չկա',
    },
    paymentMethod: {
      en: 'Payment method',
      ru: 'Метод оплаты',
      hy: 'Վճարման եղանակ',
    },
    paymentSystem: {
      en: 'Payment System',
      ru: 'Платежная система',
      hy: 'Վճարային համակարգ',
    },
    deliveryMethod: {
      en: 'Delivery method',
      ru: 'Способ доставки',
      hy: 'Առաքման եղանակը',
    },
    deliveryAddress: {
      en: 'Delivery Address',
      ru: 'Адресс доставки',
      hy: 'Առաքման հասցե',
    },
    placeholder: {
      en: 'Input in latin',
      ru: 'Ввод на кирилице',
      hy: 'Մուտքագրում հայատառ',
    },
    username: {
      en: 'Username',
      ru: 'Имя пользователя',
      hy: 'Օգտատիրոջ անուն',
    },
    login: {
      en: 'Sign in to start a session',
      ru: 'Войдите, чтобы начать сеанс',
      hy: 'Մուտք գործեք՝ սկսելու համար',
    },
    loginButton: {
      en: 'Log in',
      ru: 'Войти',
      hy: 'Մուտք',
    },
    minute: {
      en: 'minute',
      ru: 'минута',
      hy: 'րոպե',
    },
    reset: {
      en: 'Reset',
      ru: 'Збросить',
      hy: 'Չեղարկել',
    },
    notifications: {
      en: 'Notifications',
      ru: 'Уведомления',
      hy: 'Ծանուցումները',
    },
    supportText: {
      en: 'For all questions write',
      ru: 'По всем вопросам пишите',
      hy: 'Բոլոր հարցերի համար գրեք',
    },
    incorrectLogin: {
      en: 'You entered an incorrect username or password',
      ru: 'Вы ввели неверный логин или пароль',
      hy: 'Դուք մուտքագրել եք սխալ օգտվողի անուն կամ գաղտնաբառ',
    },
    address: {
      en: 'Address',
      ru: 'Адрес',
      hy: 'Հասցե',
    },
    dashboard: {
      en: 'Dashboard',
      ru: 'Доска',
      hy: 'Վահանակ',
    },
    password: {
      en: 'Password',
      ru: 'Пароль',
      hy: 'Գաղտնաբառ',
    },
    href: {
      en: 'Href',
      ru: 'Ссылка',
      hy: 'Հղում',
    },
    name: {
      en: 'Name',
      ru: 'Имя',
      hy: 'Անուն',
    },
    firstName: {
      en: 'First Name',
      ru: 'Имя',
      hy: 'Անուն',
    },
    lastName: {
      en: 'Last Name',
      ru: 'Фамилия',
      hy: 'Ազգանուն',
    },
    dateOfBirth: {
      en: 'Date Of Birth',
      ru: 'Дата рождения',
      hy: 'Ծննդյան ամսաթիվ',
    },
    desc: {
      en: 'Description',
      ru: 'Оисание',
      hy: 'Նկարագրություն',
    },
    subDesc: {
      en: 'Sub Description',
      ru: 'Под описание',
      hy: 'Ենթա նկարագրություն',
    },
    cannotPayOnline: {
      en: 'Cannot pay online',
      ru: 'Невозможно оплатить онлайн',
      hy: 'Հնարավոր չէ վճարել առցանց',
    },
    notAvailable: {
      en: 'Product not available',
      ru: 'Продукт недоступен',
      hy: 'Ապրանքը հասանելի չէ',
    },
    role: {
      en: 'Role',
      ru: 'Роль',
      hy: 'Դեր',
    },
    roles: [
      {
        _id: 'admin',
        name: { en: 'Admin', ru: 'Администратор', hy: 'Ադմին' },
      },
      {
        _id: 'manager',
        name: { en: 'Manager', ru: 'Управляющий', hy: 'Մենեջեր' },
      },
      {
        _id: 'editor',
        name: { en: 'Editor', ru: 'Редактор', hy: 'Խմբագիր' },
      },
    ],
    rate: {
      en: 'Rate',
      ru: 'Показатель',
      hy: 'Գործակից',
    },
    price: {
      en: 'Price',
      ru: 'Цена',
      hy: 'Գին',
    },
    company: {
      en: 'Company',
      ru: 'Компания',
      hy: 'Ընկերություն',
    },
    phone: {
      en: 'Phone',
      ru: 'Телефон',
      hy: 'Հեռախոս',
    },
    priceBasic: {
      en: 'Price Basic',
      ru: 'Базовая цена',
      hy: 'Հիմնական գինը',
    },
    salePriceBasic: {
      en: 'Sale Price',
      ru: 'Скидочная цена',
      hy: 'Զեղչված գին',
    },
    priceB2b: {
      en: 'Price Corporate',
      ru: 'Цена Корпоративный',
      hy: 'Գին Կորպորատիվ',
    },
    startDate: {
      en: 'Start Date',
      ru: 'Дата начала',
      hy: 'Մեկնարկի ամսաթիվ',
    },
    endDate: {
      en: 'End Date',
      ru: 'Дата окончания',
      hy: 'Ավարտի ամսաթիվ',
    },
    status: {
      en: 'Status',
      ru: 'Статус',
      hy: 'Ստատուս',
    },
    paidStatus: {
      en: 'Paid Status',
      ru: 'Статус оплаты',
      hy: 'Վճարման ստատուս',
    },
    client: {
      en: 'Client',
      ru: 'Клиент',
      hy: 'Հաճախորդ',
    },
    genders: [
      {
        name: {
          en: 'Male',
          ru: 'Мужчина',
          hy: 'Արական',
        },
      },
      {
        name: {
          en: 'Female',
          ru: 'Женщина',
          hy: 'իգական',
        },
      },
    ],
    gender: {
      en: 'Gender',
      ru: 'Пол',
      hy: 'Սեռ',
    },
    familyStatuses: [
      {
        name: {
          en: 'Single',
          ru: 'Одинокий',
          hy: 'Միայնակ',
        },
      },
      {
        name: {
          en: 'Married',
          ru: 'В браке',
          hy: 'Ամուսնացած',
        },
      },
    ],
    more: {
      en: 'Additional',
      ru: 'Дополнительный',
      hy: 'Լրացուցիչ',
    },
    addNew: {
      en: 'Add New',
      ru: 'Добавить',
      hy: 'Ավելացնել',
    },
    create: {
      en: 'Order',
      ru: 'Заказ',
      hy: 'Պատվեր',
    },
    save: {
      en: 'Save',
      ru: 'Сохранить',
      hy: 'Պահպանել',
    },
    sent: {
      en: 'Sent',
      ru: 'Отправленно',
      hy: 'Ուղարկված է',
    },
    send: {
      en: 'Send',
      ru: 'Отправить',
      hy: 'Ուղարկել',
    },
    open: {
      en: 'Open',
      ru: 'Открыть',
      hy: 'Բացել',
    },
    remove: {
      en: 'Remove',
      ru: 'Удалит',
      hy: 'Ջնջել',
    },
    cancel: {
      en: 'Cancel',
      ru: 'Отменить',
      hy: 'Չեղարկել',
    },
    edit: {
      en: 'Edit',
      ru: 'Редактировать',
      hy: 'Խմբագրել',
    },
    write: {
      en: 'Write',
      ru: 'Написать',
      hy: 'Գրել',
    },
    selectSomething: {
      en: 'Select something',
      ru: 'Выберите что-нибудь',
      hy: 'Ընտրեք մի բան',
    },
    close: {
      en: 'Close',
      ru: 'Закрыть',
      hy: 'Փակել',
    },
    closeTicket: {
      en: 'Close Ticket',
      ru: 'Закрыть билет',
      hy: 'Փակել տոմսը',
    },
    index: {
      en: 'Index',
      ru: 'Индекс',
      hy: 'Ինդեքս',
    },
    developed: {
      en: 'Developed',
      ru: 'Разработанно',
      hy: 'Ստեղծված է',
    },
    version: {
      en: 'Version',
      ru: 'Версия',
      hy: 'Տարբերակ',
    },
    mainInformation: {
      en: 'Basic information',
      ru: 'Основная информация',
      hy: 'Հիմնական տեղեկատվություն',
    },
    menu: {
      order: {
        name: { en: 'Order', ru: 'Заказ', hy: 'Պատվեր' },
        icon: 'list-order-svgrepo-com.svg',
        route: 'order',
      },
      orders: { en: 'Orders', ru: 'Заказы', hy: 'Պատվերներ' },
      clientGroup: {
        en: 'Client Groups',
        ru: 'Группы клиентов',
        hy: 'Հաճախորդների խմբեր',
      },
      clients: {
        en: 'Clients',
        ru: 'Клиенты',
        hy: 'Հաճախորդներ',
      },
      kitchenCategories: {
        en: 'Kitchen Categories',
        ru: 'Категории кухнии',
        hy: 'Խոհանոցի կատեգորիաներ',
      },
      kitchenProducts: {
        en: 'Kitchen products',
        ru: 'Продукты кухнии',
        hy: 'Խոհանոցի ապրանքներ',
      },
      storeCategories: {
        en: 'Store Categories',
        ru: 'Категории магазина',
        hy: 'Խանութի կատեգորիաներ',
      },
      storeProducts: {
        en: 'Store products',
        ru: 'Продукты кухнии',
        hy: 'Խանութի ապրանքներ',
      },
      sales: {
        en: 'Sales',
        ru: 'Продажы',
        hy: 'Վաճառքներ',
      },
      failed: {
        en: 'Failed',
        ru: 'Не удалось',
        hy: 'Ձախողվեց',
      },
      serving: {
        en: 'Serving',
        ru: 'Обслуживание',
        hy: 'Մատուցում',
      },
    },
  },
};

export default config;
