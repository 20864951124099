import React, { useEffect, useState } from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import * as API from '../../helpers/api';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import LinearProgress from '@material-ui/core/LinearProgress';
import ImageIcon from '@material-ui/icons/Image';

function StreamList() {
  let [apiData, setApiData] = useState([]);
  let [columns, setColumns] = useState([]);
  let [isBusy, setBusy] = useState(true);
  let [initialPage, setinitialPage] = useState(1);

  useEffect(() => {
    (async () => {
      const streamsRaw = await API.get(config.api.stream);
      const streams = streamsRaw;

      // const imagesRaw = await API.get(config.api.uploads, {
      //     type: { $regex: ".*image.*" },
      // });
      // const images = imagesRaw.data;

      setApiData(streamsRaw);
      setinitialPage(localStorage.getItem('initialPage'));

      setColumns([
        { title: 'Name', field: 'name' },
        {
          title: 'Playing',
          render: (rowData) => {
            let playing = '';
            streams.map((str) => {
              if (rowData._id === str._id && str.streaming)
                playing = <PlayArrowIcon color='error' />;
            });
            return playing;
          },
        },
        // {
        //     title: "Image",
        //     render: (rowData) => {
        //         let image = "";
        //         images.map((img) => {if (rowData._id === img.obj_id) image = <ImageIcon color="primary"/>});
        //         return image;
        //     },
        // },
      ]);
      setBusy(false);
    })();
  }, [isBusy]);

  if (!isBusy) {
    return (
      <ListPage
        apiData={apiData}
        columns={columns}
        CLICK={true}
        initialPage={initialPage ? initialPage : 0}
        pageSize={config.pageSize}
        title={'Streams'}
      />
    );
  } else {
    return <LinearProgress color='primary' />;
  }
}

export default StreamList;
