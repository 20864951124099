import React, { useEffect, useState } from 'react';
import { ListPage } from '../ListPage';
import config from '../../config';
import * as API from '../../helpers/api';
import LinearProgress from '@material-ui/core/LinearProgress';
import AudiotrackIcon from '@material-ui/icons/Audiotrack';
import ImageIcon from '@material-ui/icons/Image';
import { blue } from '@material-ui/core/colors';

function TrackList() {
  let [apiData, setApiData] = useState([]);
  let [columns, setColumns] = useState([]);
  let [isBusy, setBusy] = useState(true);
  let [initialPage, setinitialPage] = useState(1);

  useEffect(() => {
    (async () => {
      const tracksRaw = await API.get(config.api.track);
      setApiData(tracksRaw);

      const playlistsRaw = await API.get(config.api.trackPlaylist);
      const playlists = playlistsRaw;

      const rawAudioFiles = await API.get(config.api.uploads, {
        type: { $regex: '.*audio.*' },
      });
      const audioFiles = rawAudioFiles;

      const imagesRaw = await API.get(config.api.uploads, {
        type: { $regex: '.*image.*' },
      });
      const images = imagesRaw;

      setinitialPage(localStorage.getItem('initialPage'));

      setColumns([
        { title: 'Name', field: 'name' },
        {
          title: 'Playlist',
          field: 'playlist',
          render: (rowData) => {
            let playlist = '';
            playlists.map((plylist) => {
              if (rowData.playlist_id === plylist._id) {
                playlist = plylist.name;
              }
            });
            return playlist;
          },
        },
        {
          title: 'Mp3',
          field: 'mp3',
          render: (rowData) => {
            let audio = '';
            audioFiles.map((aud) => {
              if (rowData._id === aud.obj_id) {
                audio = <AudiotrackIcon style={{ color: blue[500] }} />;
              }
            });
            return audio;
          },
        },
        // {
        //     title: "Image",
        //     render: (rowData) => {
        //         let image = "";
        //         images.map((img) => { if (rowData._id === img.obj_id) image = <ImageIcon color="primary" /> });
        //         return image;
        //     },
        // },
      ]);
      setBusy(false);
    })();
  }, [isBusy]);

  if (!isBusy) {
    return (
      <ListPage
        apiData={apiData}
        columns={columns}
        CLICK={true}
        title={'Tracks'}
        initialPage={initialPage ? initialPage : 0}
        pageSize={config.pageSize}
      />
    );
  } else {
    return <LinearProgress color='primary' />;
  }
}

export default TrackList;
