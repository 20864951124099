import React, { useState, useEffect } from 'react';
import SinglePage from '../SinglePage';
import { useParams, useHistory } from 'react-router-dom';
import * as API from '../../helpers/api';
import config from '../../config';
import LinearProgress from '@material-ui/core/LinearProgress';

export default function AdPage() {
  let history = useHistory();
  let id = useParams().id;
  const [isBusy, setBusy] = useState(null);
  const [image, setImage] = useState(undefined);
  const [file, setFile] = useState(undefined);
  const [name, setName] = useState('');
  const [playlist_id, setPlaylist_id] = useState('');
  const [playlist, setPlaylist] = useState('');
  const [playlists, setPlaylists] = useState('');

  let modelSendToServer = {
    name,
    playlist_id: playlist ? playlist._id : null,
  };

  //! components specific functions
  async function createItem() {
    setBusy(true);
    await API.post(config.api.ad, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function updateItem() {
    setBusy(true);
    await API.update(config.api.ad, id, modelSendToServer);
    let previousUrl = '/' + history.location.pathname.split('/')[1] + '/';
    history.push(previousUrl);
  }
  async function removeItem() {
    await API.remove(config.api.ad, id);
    history.goBack();
    setBusy(true);
  }
  async function removeFile(id) {
    setFile('');
    setImage('');
    await API.remove(config.api.uploads, id);
    setBusy(true);
  }
  async function uploadFile(files, lang) {
    if (id === 'add') {
      try {
        modelSendToServer.name = files[0].name.split('.')[0];
        const rowData = await API.post(config.api.ad, modelSendToServer);
        id = rowData._id;
        await API.upload(id, files, lang);
      } catch (error) {
        console.error(error);
      }
      await history.push(`${id}`);
    } else {
      await API.upload(id, files, lang);
    }
    setBusy(true);
  }

  //promise.all
  useEffect(() => {
    (async () => {
      if (id !== 'add') {
        const rowData = API.get(config.api.ad, { _id: id });
        const rowFile = API.get(config.api.uploads, {
          obj_id: id,
          type: { $regex: '.*audio.*' },
        });
        const rowImage = API.get(config.api.uploads, {
          obj_id: id,
          type: { $regex: '.*image.*' },
        });
        const rowPlaylists = API.get(config.api.adPlaylist);
        Promise.all([rowData, rowFile, rowImage, rowPlaylists]).then(
          (value) => {
            if (value[0][0]) {
              let rowData = value[0][0];
              setName(rowData.name);
              setPlaylist_id(rowData.playlist_id);

              if (value[1][0]) setFile(value[1][0]);
              if (value[2][0]) setImage(value[2][0]);

              if (value[3][0]) {
                let rowPlaylists = value[3];
                setPlaylists(rowPlaylists);
                rowPlaylists.forEach((pllist) => {
                  if (pllist._id === value[0][0].playlist_id) {
                    setPlaylist(pllist);
                  }
                });
              }
            } else {
              console.error(
                'We cannot get page information from server, please connect to site administrator'
              );
            }
          }
        );
      } else {
        API.get(config.api.adPlaylist)
          .then((res) => {
            setPlaylists(res);
          })
          .catch((err) => console.error(err));
      }
      setBusy(false);
    })();
  }, [isBusy]);

  if (isBusy === null) return null;
  else if (isBusy === true) return <LinearProgress color='primary' />;
  else if (isBusy === false)
    return (
      <SinglePage
        inputs={[
          {
            value: name,
            setter: setName,
            type: 'textfield',
            label: 'Name',
          },
          {
            value: playlist,
            setter: setPlaylist,
            type: 'optionList',
            optionListValue: playlists,
            label: 'Playlists',
          },
        ]}
        filesComponent={[
          // {
          //   name: "image",
          //   file: image,
          //   filesUploadLimit: 1,
          //   acceptedFiles: ["image/*"],
          //   uploadFile: uploadFile,
          //   removeFile: removeFile,
          //   buttonAddText: "add",
          //   buttonOpenText: "open",
          //   buttonRemoveText: "remove",
          //   dropzoneText: 'Click on the "Add" button to add image',
          // },
          {
            name: 'file',
            file: file,
            filesUploadLimit: 1,
            acceptedFiles: ['audio/*'],
            uploadFile: uploadFile,
            removeFile: removeFile,
            buttonAddText: 'add',
            buttonOpenText: 'open',
            buttonRemoveText: 'remove',
            dropzoneText: 'Click on the "Add" button to add file',
          },
        ]}
        isBusy={isBusy}
        setBusy={setBusy}
        route={config.api.ad}
        CREATE={createItem}
        UPDATE={updateItem}
        REMOVE={removeItem}
      />
    );
}
