import React, { useState, forwardRef, useEffect } from "react";
import MaterialTable, { MTableToolbar } from "material-table";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/AddBox";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export function ListPage(props) {
  const { GET, POST, UPDATE, REMOVE, pageTitle } = props;
  const [apiData, setApiData] = useState(props.apiData);
  const onclick = props.CLICK;
  let history = useHistory();

  function onRowAdd(newData) {
    return new Promise((resolve, reject) => {
      POST(newData)
        .then(() => {
          setTimeout(() => resolve(), 1000);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  useEffect(() => {
    setApiData(props.apiData);
  }, [props.apiData]);

  const tableIcons = {
    Add: forwardRef((props, ref) => <Add {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownwardIcon {...props} ref={ref} />
    )),
  };

  return (
    <div style={{ maxWidth: "100%" }}>
      <Typography align="center" variant="h4" gutterBottom={true}>
        {pageTitle}
      </Typography>
      {apiData ? (
        <MaterialTable
          columns={props.columns}
          data={apiData}
          title={props.title ? props.title : ""}
          icons={tableIcons}
          onChangePage={(page)=>{
            localStorage.setItem('initialPage', page)
          }}
          options={{ 
            pageSize: props.pageSize,
            initialPage: +props.initialPage
           }}
          actions={[
            onclick
              ? {
                  icon: Edit,
                  tooltip: "Edit",
                  onClick: (event, rowData) => {
                    localStorage.setItem('tableData', JSON.stringify(rowData.tableData.id))
                    history.push(`${rowData._id}`);
                  },
                }
              : null,
            onclick
              ? {
                  icon: Add,
                  tooltip: "Add User",
                  isFreeAction: true,
                  onClick: (event) => {
                    history.push(`add`);
                  },
                }
              : null,
          ]}
          editable={
            !onclick
              ? {
                  onRowAdd: onRowAdd,
                  onRowUpdate: (newData, oldData) =>
                    new Promise((resolve, reject) => {
                      UPDATE(oldData._id, newData)
                        .then(() => {
                          setTimeout(() => resolve(), 1000);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    }),
                  onRowDelete: (oldData) =>
                    new Promise((resolve, reject) => {
                      REMOVE(oldData._id)
                        .then(() => {
                          setTimeout(() => resolve(), 1000);
                        })
                        .catch((error) => {
                          reject(error);
                        });
                    }),
                }
              : null
          }
        />
      ) : null}
    </div>
  );
}
