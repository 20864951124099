import React, { Component, Fragment, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import { useParams, useHistory } from 'react-router-dom';
//! import modules

import Input from './modules/input';
import { ImageComponent } from './modules/imageComponent';
import { FilesComponent } from './modules/filesComponent';
import { SliderComponent } from './modules/sliderComponent';
import SortableTable from './modules/playlistPageTracks';

// ! save-cancel-delete buttons
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

import { orange, purple } from '@material-ui/core/colors';

// !Date stuff
import 'date-fns';

import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
// ! Delete button with icon
import IconButton from '@material-ui/core/IconButton';

// !Select option stuff
import { makeStyles } from '@material-ui/core/styles';

//! loader stuff
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  singlePageContent: {
    marginBottom: 50,
  },
  singlePageBtnGroup: {
    position: 'fixed',
    textAlign: 'right',
    right: 0,
    left: 0,
    bottom: 0,
    height: 50,
    zIndex: 999,
    background: '#fafafa',
    borderTop: '1px solid lightgrey',
  },
}));

export default function SinglePage(props) {
  const {
    inputs,
    filesComponent,
    sliderComponent,
    sortableTable,
    UPDATE,
    REMOVE,
    isBusy,
    CREATE,
    streaming,
    stop,
    setBusy,
    handleStreamState,
  } = props;

  let history = useHistory();
  let editMode;
  let pageTitle;
  const classes = useStyles();
  let [screenWidth] = useState(window.innerWidth.toString());
  let [loading, setloading] = useState(Boolean);

  if (useParams().id === 'add') {
    editMode = false;
    if (history.location.pathname.split('/')[1] === 'playlist') {
      pageTitle = 'Add playlist';
    } else if (history.location.pathname.split('/')[1] === 'track') {
      pageTitle = 'Add track';
    } else {
      pageTitle = 'Add one';
    }
  } else {
    editMode = true;
    pageTitle = `Editing ${history.location.pathname.split('/')[1]}`;
  }

  useEffect(() => {}, [loading]);
  useEffect(() => {}, [streaming]);

  // ! MAIN BUTTONS --------------SAVE------REMOOVE------CANCEL
  const saveItem = () => {
    if (editMode) UPDATE();
    else CREATE();
  };
  const removeItem = () => {
    REMOVE();
  };
  const cancelBack = () => {
    history.goBack();
  };

  let handleStreamStateChange = (e) => {
    setloading(true);
    setTimeout(() => {
      setloading(false);
      setBusy(true);
    }, 2000);
    handleStreamState(e);
  };

  if (isBusy === null) return null;
  else if (isBusy === true) return <LinearProgress color='primary' />;
  else if (isBusy === false)
    return (
      <Fragment>
        <div className={classes.singlePageContent}>
          <Typography align='center' variant='h4' gutterBottom={true}>
            {pageTitle}
          </Typography>
          {editMode && sortableTable && (
            <SortableTable
              route={sortableTable.route}
              data={sortableTable.data}
              selection={sortableTable.selection}
              noControl={sortableTable.noControl}
              title={sortableTable.title}
              playlistRoute={sortableTable.playlistRoute}
              columns={sortableTable.columns}
              pageSize={5}
            />
          )}
          <form onSubmit={saveItem}>
            {inputs
              ? inputs.map((input, index) => {
                  return (
                    <Input
                      label={input.label}
                      value={input.value}
                      setter={input.setter}
                      type={input.type}
                      optionListValue={input.optionListValue}
                      inputProps={input.inputProps}
                      key={index}
                    />
                  );
                })
              : null}
            {sliderComponent &&
              sliderComponent.map((component, index) => {
                return (
                  <SliderComponent
                    files={component.files}
                    name={component.name}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    buttonAddText={component.buttonAddText}
                    uploadFile={component.uploadFile}
                    removeFile={component.removeFile}
                    dropzoneText={component.dropzoneText}
                    lang={component.lang}
                    key={index}
                  />
                );
              })}
            {filesComponent &&
              filesComponent.map((component, index) => {
                return (
                  <FilesComponent
                    file={component.file}
                    filesUploadLimit={component.filesUploadLimit}
                    acceptedFiles={component.acceptedFiles}
                    uploadFile={component.uploadFile}
                    removeFile={component.removeFile}
                    lang={component.lang}
                    buttonAddText={component.buttonAddText}
                    buttonOpenText={component.buttonOpenText}
                    buttonRemoveText={component.buttonRemoveText}
                    dropzoneText={component.dropzoneText}
                    key={index}
                    index={index}
                    destiny={component.destiny}
                    setBusy={setBusy}
                    adminMode={component.adminMode}
                    thumbSizeWidth={component.thumbSizeWidth}
                    thumbSizeHeight={component.thumbSizeHeight}
                    responsiveImages={component.responsiveImages}
                  />
                );
              })}
          </form>
        </div>
        <div className={classes.singlePageBtnGroup}>
          <Button
            variant='contained'
            color='primary'
            className={classes.button}
            startIcon={<SaveIcon />}
            onClick={saveItem}
            disabled={streaming || loading}
          >
            {screenWidth < 500 ? null : 'Save'}
          </Button>

          {editMode ? (
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
              startIcon={<DeleteIcon />}
              onClick={removeItem}
              disabled={streaming || loading}
            >
              {screenWidth < 500 ? null : 'Remove'}
            </Button>
          ) : (
            <Button
              variant='contained'
              color='secondary'
              className={classes.button}
              startIcon={<CancelIcon />}
              onClick={cancelBack}
            >
              {screenWidth < 500 ? null : 'Cancel'}
            </Button>
          )}
          {streaming !== undefined ? (
            <>
              <Button
                variant='contained'
                color='default'
                value={streaming}
                className={classes.button}
                loading={loading}
                startIcon={
                  loading ? null : !streaming ? <PlayArrowIcon /> : <StopIcon />
                }
                onClick={handleStreamStateChange}
                disabled={loading}
              >
                {loading ? 'Loading' : !streaming ? 'PLAY' : 'STOP'}
              </Button>
            </>
          ) : null}
        </div>
      </Fragment>
    );
}
